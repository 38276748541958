// React
import { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { useParams } from 'react-router-dom'
// Flowbite & Icons
import { Cog6ToothIcon, PlusCircleIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline'
// Components & Templates
import { PageWithSidebar } from '../../../components/Layout/Page'
import { Sidebar, SidebarMenuItem } from '../../../components/Layout/Sidebar'
// Services
import { useDb } from '../../../services/Db'
import { getWorkspace } from '../../../services/Workspace'
// Config
import { DbContextInterface, DbRights } from '../../../config/Db'
import { ApplicationAssistant } from './Assistant'
import { getContexts, insertContext, deleteContext, updateContext } from '../../../services/Context'
import { useToast } from '../../../components/Contexts/ToastContext'
import { useTranslation } from 'react-i18next'
import { useStorage } from '../../../components/Hooks/Storage'


export default function WorkspaceApplication() {
    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)
    const toast = useToast()
    const { t } = useTranslation()

    const { workspace_ref } = useParams();

    const [workspace, setWorkspace] = useState<any | null>(null)
    const [contextRef, setContextRef] = useState<string | undefined | null>() // Null is used to delete the storage
    const [contexts, setContexts] = useState<Array<SidebarMenuItem>>([])
    const { data: lastContext } = useStorage('lastContext', contextRef)


    const newContextItem: SidebarMenuItem = {
        name: 'newcontext',
        label: 'application.sidebar.newcontext',
        icon: <PlusCircleIcon className='h-6 w-6' />,
        rights: [DbRights.AppTranscriptReadWrite],
        action: () => {
            if (workspace.workspace) {
                insertContext(db, workspace.workspace.id, t('application.transcript.context.new.default')).then(() => {
                    toast.fire('success', t('application.transcript.context.new.success', { name: t('application.transcript.context.new.default') }), 3000)
                    reloadContexts()
                }).catch(() => {
                    toast.fire('failure', t('application.transcript.context.new.failure', { name: t('application.transcript.context.new.default') }), 3000)

                })
            }
        }
    }

    const handlePageChange = (item: SidebarMenuItem) => {
        setContextRef(item.name)
    }

    const updateContextTitle = (id: number, title: string) => {
        updateContext(db, id, { name: title }).then(() => {
            reloadContexts()
        })
    }

    const removeCurrentContext = () => {
        if (workspace.workspace?.id && contextRef) {
            deleteContext(db, workspace.workspace.id, contextRef).then(() => {
                toast.fire('success', t('application.transcript.context.remove.success'), 3000)
                reloadContexts()
            }).catch(() => {
                toast.fire('failure', t('application.transcript.context.remove.failure'), 3000)
            })
        }
    }

    const reloadContexts = () => {
        if (workspace?.workspace.id) {
            getContexts(db, workspace?.workspace.id).then((ctxs: Array<DbContextInterface>) => {
                const cs: Array<SidebarMenuItem> = ctxs.map((ctx: DbContextInterface) => {
                    return {
                        name: ctx.ref || '',
                        label: ctx.name || '',
                        icon: <SpeakerWaveIcon className='h-6 w-6' />,
                        rights: [DbRights.AppTranscriptRead, DbRights.AppTranscriptReadWrite],
                        action: handlePageChange
                    }
                })
                cs.push(newContextItem)
                setContexts(cs)
            })
        }
    }

    const spaceSwitch: SidebarMenuItem = {
        name: 'settings',
        label: 'sidebar.settings',
        icon: <Cog6ToothIcon className='h-6 w-6' />,
        rights: [],
        action: () => { window.location.assign(`/workspaces/${workspace_ref}/settings`) }
    }


    useEffect(() => {
        if (auth.user?.access_token && workspace_ref) {
            getWorkspace(db, auth.user?.profile.sub, workspace_ref)
                .then((wk) => {
                    setWorkspace(wk)
                })
        }
    }, [auth.user, workspace_ref])

    useEffect(() => {
        reloadContexts()
    }, [workspace])

    useEffect(() => {
        if (contexts.length <= 1) {
            setContextRef(null)
        } else {
            if (contexts.filter((c) => c.name === lastContext).length === 0) {
                setContextRef(contexts[0].name)
            } else {
                setContextRef(lastContext)
            }
        }
    }, [contexts, lastContext])

    return (
        <PageWithSidebar sidebar={<Sidebar opened={true} selector={true} item={contextRef} items={contexts} spaceSwitch={spaceSwitch} />}>
            <ApplicationAssistant
                contextRef={contextRef || ''}
                workspaceConfig={workspace}
                workspace_ref={workspace_ref || ''}
                updateContextTitle={updateContextTitle}
                removeCurrentContext={removeCurrentContext}
            />
        </PageWithSidebar>
    )
}