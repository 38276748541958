import { PostgrestClient, PostgrestFilterBuilder, PostgrestTransformBuilder } from '@supabase/postgrest-js'

export const useDb = (schema: string = 'app', token: string | undefined): PostgrestClient<any, string, any> => {
    const postgrest = new PostgrestClient(import.meta.env.VITE_DBAPI_URL, {
        schema: schema,
        headers: {
            'Authorization': `Bearer ${token || ''}`,
            'Prefer': 'return=representation'
        }
    })
    return postgrest
}

export const select = (ctx: PostgrestClient<any, string, any>, from: string, columns: string = '*'): PostgrestFilterBuilder<any, any, string[], string, unknown> => {
    return ctx.from(from).select(columns, { count: "exact" })
}

export const insert = (ctx: PostgrestClient<any, string, any>, from: string, data: any): PostgrestTransformBuilder<any, any, any[], string, unknown> => {
    const now = new Date()
    return ctx.from(from).upsert({
        created_at: now,
        updated_at: now,
        ...data
    }, { onConflict: 'ref' }).select()
}

export const bulkinsert = (ctx: PostgrestClient<any, string, any>, from: string, data: Array<any>): PostgrestTransformBuilder<any, any, any[], string, unknown> => {
    const now = new Date()
    return ctx.from(from).upsert(data.map((value: any) => {
        return {
            created_at: now,
            updated_at: now,
            ...value
        }
    })).select()
}

export const update = (ctx: PostgrestClient<any, string, any>, from: string, data: any): PostgrestFilterBuilder<any, any, null, string, unknown> => {
    const now = new Date()
    return ctx.from(from).update({
        ...data,
        updated_at: now
    })
}

export const remove = (ctx: PostgrestClient<any, string, any>, from: string): PostgrestFilterBuilder<any, any, null, string, unknown> => {
    return ctx.from(from).delete()
}