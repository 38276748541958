import { useState, useEffect } from "react";

export const useStorage = <T>(key: string, value: T) => {
    const [data, setData] = useState<T | undefined>(undefined);

    useEffect(() => {

        const readStorage = (key: string) => {
            if (typeof localStorage !== 'undefined') {
                const item = localStorage.getItem(key)
                if (item) {
                    setData(JSON.parse(item))
                } else {
                    setData(undefined)
                }
            }
        }

        const writeStorage = (key: string, value: T) => {
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem(key, JSON.stringify(value))
            }
        }

        const deleteStorage = (key: string) => {
            if (typeof localStorage !== 'undefined') {
                localStorage.removeItem(key)
            }
        }

        if (value === null) {
            deleteStorage(key)
        } else if (value === undefined) {
            readStorage(key)
        } else {
            writeStorage(key, value);
            readStorage(key);
        }
    }, [key, value]);

    return { data };
}