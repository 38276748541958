import { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";

export const useFetch = <T>(url: string) => {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const auth = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${import.meta.env.VITE_HTTP_URL}${url}`, {
                    headers: {
                        'Authorization': `Bearer ${auth.user?.access_token}`
                    }
                });
                const result = await response.json();
                setData(result);
            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [url]);

    return { data, loading, error };
}

export const usePost = <S, T>(url: string, data: S | undefined) => {
    const [response, setResponse] = useState<T | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const auth = useAuth();

    useEffect(() => {
        const postData = async (data_: S | undefined) => {
            if (!data_) return;
            try {
                if (loading) return
                setLoading(true)
                const response = await fetch(`${import.meta.env.VITE_HTTP_URL}${url}`, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    headers: {
                        'Content-Type': (data_ instanceof ArrayBuffer) ? 'octet-stream' : 'application/json',
                        'Authorization': `Bearer ${auth.user?.access_token}`
                    },
                    body: (data_ instanceof ArrayBuffer) ? data_ : JSON.stringify(data_)
                });
                const result = await response.json();
                setResponse(result);
            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        postData(data);
    }, [url, data]);

    return { response, loading, error };
}
