import { Fragment, useEffect, useRef, useState } from "react";
import { WorkspaceProps } from "../Index";
import { PageTitle } from "../../../components/Elements/Title";
import { useTranslation } from "react-i18next";
import { Badge, Button, Dropdown, Spinner } from "flowbite-react";
import { audioBufferToWav } from "../../../services/Audio";
import { ChevronDownIcon, DocumentTextIcon, FilmIcon, PaperAirplaneIcon, SpeakerWaveIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth } from "react-oidc-context";
import { DbAgentInterface, DbContextInterface, DbContextMessageInterface, DbRights } from "../../../config/Db";
import { deleteContextMessage, getContext, insertContextMessage, updateContext } from "../../../services/Context";
import { useDb } from "../../../services/Db";
import { NameEditable } from "../../../components/Elements/NameEditable";
import Moment from "react-moment";
import { useToast } from "../../../components/Contexts/ToastContext";
import { UserHasRights } from "../../../services/Workspace";
import { ChatCard } from "../../../components/Elements/Chatcard";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getAgents } from "../../../services/Agent";
import { usePost } from "../../../components/Hooks/Fetch";

export interface TranscriptAssistantProps extends WorkspaceProps {
    contextRef: string | undefined
    updateContextTitle: (identifier: number, value: string) => void
    removeCurrentContext: () => void
}


interface chatMessage {
    role: 'system' | 'user' | 'assistant' | 'human' | 'robot',
    content: string
    end?: boolean
}

interface TranscriptResponse {
    text: string
    context: string
}

interface TranscriptRequest {
    b64audio: string
    prompt: string
}

const getMediaLink = (src: Blob | ArrayBuffer | string, type: string): string => {
    if (src instanceof Blob) {
        return URL.createObjectURL(src)
    } else {
        const blob = new Blob([src], { type: type })
        return URL.createObjectURL(blob)
    }
}

const downloadLink = (url: string, name: string,) => {
    const a = document.createElement('a')
    a.href = url
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

export function ApplicationAssistant(props: TranscriptAssistantProps) {

    const auth = useAuth()
    const db = useDb('app', auth.user?.access_token)
    const { t, i18n } = useTranslation()
    const toast = useToast()


    const [context, setContext] = useState<DbContextInterface | undefined>(undefined)
    const [agents, setAgents] = useState<DbAgentInterface[]>([])

    /**
     * Chat variables
     */
    const [chatMessages, setChatMessages] = useState<DbContextMessageInterface[]>([])
    const [lastMessage, setLastMessage] = useState<chatMessage | undefined>(undefined)
    const [chatLoading, setChatLoading] = useState<boolean>(false)
    const [inputText, setInputText] = useState<string>('')
    const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | undefined>(undefined)
    const [audioWavs, setAudioWavs] = useState<ArrayBuffer[]>([])
    const [runningTranscript, setRunningTranscript] = useState<TranscriptRequest | undefined>()
    const [transcriptText, setTranscriptText] = useState<string>('')
    const [transcriptStatus, setTranscriptStatus] = useState<string>('')
    const audioCanvasRef = useRef<HTMLCanvasElement>(null)
    const [dragOver, setDragOver] = useState<boolean>(false)


    const { response: transcriptResponse, loading: transcriptLoading } = usePost<TranscriptRequest, TranscriptResponse>(`/workspaces/${props.workspace_ref}/contexts/${props.contextRef}/transcript`, runningTranscript)


    const messagesEndRef = useRef<HTMLDivElement>(null)
    const { sendJsonMessage, readyState } = useWebSocket(
        `${import.meta.env.VITE_WS_URL}/workspaces/${props.workspace_ref}/chat`,
        {
            share: false,
            reconnectAttempts: 360,
            reconnectInterval: 10,
            shouldReconnect: () => true,
            onClose: () => { },
            onMessage: (event: WebSocketEventMap["message"]) => {
                const msg: chatMessage = JSON.parse(event.data)
                setChatLoading(false)
                if (msg.end == true && context) {
                    insertContextMessage(db, context.id || 0, 'assistant', msg.content).then((newmsg: DbContextMessageInterface) => {
                        setChatMessages(prev => { return [...prev, newmsg] })
                        setLastMessage(undefined)
                        scrollToBottom()
                    })
                } else {
                    setLastMessage(msg)
                    scrollToBottom()
                }
            },
        }
    )

    /**
     * Common methods
     */

    const updateContextTitle = (id: number, title: string) => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            setContext(prev => {
                if (prev) {
                    return {
                        ...prev,
                        name: title
                    }
                }
            })
            props.updateContextTitle(id, title)
        }
    }

    const updateContextAgent = (agt: DbAgentInterface) => {
        if (context && context.id) {
            context.workspace_agent_id = agt.id
            updateContext(db, context.id, context).then((value: DbContextInterface) => {
                getContext(db, props.workspaceConfig.workspace?.id || 0, value.ref || '').then((ctx) => {
                    if (ctx) {
                        setContext(ctx)
                        reloadMessages(ctx)
                    }
                })
            })
        }
    }

    const handleInput = (file: File) => {
        if (UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            if (file.type.startsWith('audio/') || file.type.startsWith('video/')) {
                readMedia(file)
            } else if (file.type.startsWith('text/')) {
                extractText(file)
            }
        }
    }

    // Audio/Video management
    const drawWave = (canvas: HTMLCanvasElement | null, audioBuffer: AudioBuffer) => {
        if (!canvas) return
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
        if (!ctx) return

        const data: Float32Array = audioBuffer.getChannelData(0)
        const steps = Math.ceil(data.length / canvas.clientWidth)

        ctx.clearRect(0, 0, canvas.clientWidth, canvas.clientHeight);
        ctx.fillStyle = '#4a2ad8'
        for (let i = 0; i < canvas.clientWidth; i++) {
            let min: number = 1.0
            let max: number = -1.0
            for (let j = 0; j < steps; j++) {
                const d = data[(i * steps) + j];
                min = Math.min(d, min)
                max = Math.max(d, max)
            }
            ctx.fillRect(i, (1 + min) * (canvas.clientHeight), 1, Math.max(1, (max - min) * (canvas.clientHeight)));
        }
    }

    const readMedia = (file: File) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            const arrBuffer: ArrayBuffer = event.target?.result as ArrayBuffer;
            createAudio(arrBuffer)
        };
        reader.readAsArrayBuffer(file);
    }

    const extractText = (file: File) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            const text = event.target?.result as string || ''
            setInputText(text)
        }
        reader.readAsText(file);
    }

    const createAudio = (arrBuffer: ArrayBuffer) => {
        const audioCtx = new AudioContext({ sampleRate: 8000 });
        audioCtx.decodeAudioData(arrBuffer, function (audioBuffer: AudioBuffer) {
            setAudioBuffer(audioBuffer)
        });
    }

    /**
     * Chat phase methods
     */
    const sendMessage = (msg: string) => {
        if (context && context.id && msg.trim().length > 0 && props.workspaceConfig?.workspace && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            setChatLoading(true)
            insertContextMessage(db, context.id, 'user', msg).then((newmsg: DbContextMessageInterface) => {
                setChatMessages(prev => {
                    const n = [...prev, newmsg]
                    sendJsonMessage({
                        auth: { token: auth.user?.access_token },
                        context_ref: props.contextRef,
                        messages: n.map((v: DbContextMessageInterface) => { return { role: v.role, content: v.content } })
                    })
                    return n
                })
            }).catch(() => {
                toast.fire('failure', t('application.transcript.chat.error'), 3000)
            })
        }
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'instant' })
    }

    const reloadMessages = (ctx: DbContextInterface) => {
        const messages = ctx?.context_message || []
        setChatMessages([{
            role: 'system',
            content: ctx?.workspace_agent?.system_prompt || '',
            hidden: false
        }, ...messages])
    }


    const reloadContext = () => {
        if (props.workspaceConfig?.workspace && props.workspaceConfig.workspace.id && props.contextRef && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptReadWrite])) {
            getContext(db, props.workspaceConfig.workspace.id, props.contextRef).then((value: DbContextInterface | undefined) => {
                setContext(value)
                if (value?.workspace_agent) {
                    reloadMessages(value)
                }
            })
        } else {
            setContext(undefined)
        }
    }


    /**
     * UseEffects
     */
    // On contextRef of Workspace changes
    useEffect(() => {
        reloadContext()
    }, [props.workspaceConfig?.workspace, props.contextRef])

    // On audioBuffer change, draw wave
    useEffect(() => {
        if (audioCanvasRef.current && audioBuffer) {
            drawWave(audioCanvasRef.current, audioBuffer)
            setDragOver(false)
        }
    }, [audioBuffer])

    // On chatmessage change, scroll to Bottom
    useEffect(() => {
        if (chatMessages.length > 0) {
            scrollToBottom()
        }
    }, [chatMessages])

    // On chunkResponse change, set mp3Chunks
    useEffect(() => {
        if (audioWavs.length > 0) {
            setTranscriptStatus(t('application.transcript.transcripting', { chunks: audioWavs.length }))
            const base64 = btoa(
                new Uint8Array(audioWavs[0] || new ArrayBuffer(0))
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            setRunningTranscript({
                b64audio: base64,
                prompt: transcriptResponse?.context || ''
            })
        }
    }, [audioWavs])

    useEffect(() => {
        if (transcriptResponse) {
            setTranscriptText(prev => { return `${prev || ''} ${transcriptResponse.text}` })
            setAudioWavs(prev => { return prev.slice(1) })
        }
    }, [transcriptResponse])

    useEffect(() => {
        if (transcriptText && transcriptText.length > 0 && audioWavs.length == 0) {
            setLastMessage(undefined)
            sendMessage(transcriptText)
            setTranscriptText('')
        }
    }, [transcriptText, audioWavs])

    useEffect(() => {
        if (props.workspaceConfig && db && auth.user) {
            getAgents(db, props.workspaceConfig.workspace).then((values: DbAgentInterface[]) => {
                setAgents(values)
            }).catch(() => setAgents([]))
        }
    }, [props.workspaceConfig, auth.user])


    return (
        <>
            {
                props.workspaceConfig?.role && UserHasRights(props.workspaceConfig?.role, [DbRights.AppTranscriptRead, DbRights.AppTranscriptReadWrite]) && context ?
                    <>
                        {/* Title */}
                        <PageTitle
                            margin='mb-4'
                            rightbutton={
                                <div className="flex flex-row gap-2 items-center">
                                    <div className="w-80">

                                        <Dropdown
                                            hidden={true}
                                            label=""
                                            renderTrigger={
                                                () =>
                                                    <div className='w-full font-mono text-gray-900 dark:text-gray-200 truncate cursor-pointer'>
                                                        <div className="w-full flex flex-row justify-between">
                                                            <div className="flex flex-row gap-2 items-center text-xs">
                                                                {context.workspace_agent?.name}
                                                                <Badge size='xs'>{context.workspace_agent?.agent_model?.identifier}</Badge>
                                                            </div>
                                                            <div>
                                                                <ChevronDownIcon className='ml-2 h-4 w-4 inline'></ChevronDownIcon>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        >
                                            {
                                                agents.map((agt: DbAgentInterface) => (
                                                    <div key={agt.ref}>
                                                        <Dropdown.Item onClick={() => updateContextAgent(agt)}>
                                                            <div className="flex flex-row gap-2 items-center">
                                                                {agt?.name} ({agt?.agent_model?.identifier})
                                                            </div>
                                                        </Dropdown.Item>
                                                    </div>

                                                ))
                                            }
                                        </Dropdown>
                                    </div>
                                    <Button aria-label='Remove current assistant' color="transparent" onClick={() => { props.removeCurrentContext() }}>
                                        <TrashIcon className="text-red-800 dark:text-red-600 h-5 w-5" />
                                    </Button>
                                </div>

                            }
                            subtitle={
                                <>
                                    <span>{t('application.transcript.context.created')} <Moment locale={i18n.language} fromNow>{context.created_at}</Moment></span>
                                </>
                            }
                        >
                            <NameEditable className='p-1' name={context.name || ''} identifier={context.id} save={updateContextTitle} maxchars={52} />
                        </PageTitle>

                        <div
                            className="flex flex-col h-full items-center overflow-y-scroll"
                            onDragOver={(event) => {
                                console.log('event', event)
                                event.preventDefault()
                                setDragOver(true)
                            }}
                            onDragLeave={(event) => {
                                event.preventDefault()
                                setDragOver(false)
                            }}
                            onDrop={(event) => { event.preventDefault(); handleInput(event.dataTransfer?.files[0]); setInputText(''); }}
                        >
                            {
                                chatMessages.map((value: DbContextMessageInterface, index: number) => (
                                    <Fragment key={index}>
                                        {
                                            <ChatCard
                                                key={value.id}
                                                role={value.role}
                                                user={auth.user?.profile}
                                                remove={() => {
                                                    deleteContextMessage(db, context.id || 0, value?.id || 0).then(() => {
                                                        setChatMessages(prev => {
                                                            return prev.filter((m: DbContextMessageInterface) => { return m.id != value.id })
                                                        })
                                                    })
                                                }}
                                                download={() => {
                                                    downloadLink(getMediaLink(value.content, 'text/markdown'), `${context.name}.md`)
                                                }}
                                            >{value.content}</ChatCard>
                                        }
                                    </Fragment>
                                ))
                            }
                            {
                                lastMessage ? <ChatCard role={lastMessage.role} user={auth.user?.profile}>{lastMessage.content}</ChatCard> : <></>
                            }

                            <div ref={messagesEndRef} className='w-full min-h-[116px]'></div>
                            <div className="fixed bottom-0 flex flex-col justify-end items-center bottom-4 w-full bg-transparent">
                                {
                                    audioBuffer && audioWavs.length == 0 ?
                                        <>
                                            <canvas className="w-2/3 h-20" ref={audioCanvasRef}></canvas>
                                            <div className="absolute bottom-3 left-[84%] text-primary-700 dark:text-primary-200 cursor-pointer">
                                                {transcriptLoading ? <Spinner className="h-6 w-6"></Spinner> : <PaperAirplaneIcon onClick={() => { setAudioWavs(audioBufferToWav(audioBuffer, false, true)); setAudioBuffer(undefined) }} className="h-6 w-6" />}
                                            </div>
                                            <div className="absolute bottom-12 left-[84%] text-primary-700 dark:text-primary-200 cursor-pointer">
                                                {transcriptLoading ? <></> : <XMarkIcon onClick={() => { setAudioBuffer(undefined); setAudioWavs([]); }} className="h-6 w-6" />}
                                            </div>
                                        </> : audioWavs.length > 0 ?
                                            <div className="w-2/3 h-20">
                                                {transcriptStatus ? <div className="mt-4 text-xs text-gray-500 dark:text-primary-400">{transcriptStatus}</div> : <></>}

                                                <div className="absolute bottom-3 left-[84%] text-primary-700 dark:text-primary-200 cursor-pointer">
                                                    <Spinner className="h-6 w-6" />
                                                </div>
                                            </div>
                                            : dragOver ?
                                                <div className="bg-gray-100 dark:bg-gray-800 outline-primary-900 ring-0 text-sm mb-2 mx-4 shadow-lg w-2/3 px-2 py-3 rounded rounded-lg border border-primary-700 h-40">{
                                                    <div className="w-full h-full justify-center items-center flex gap-4"><DocumentTextIcon className="h-8 w-8"></DocumentTextIcon><SpeakerWaveIcon className="h-8 w-8"></SpeakerWaveIcon><FilmIcon className="h-8 w-8"></FilmIcon></div>
                                                }</div> :
                                                <>
                                                    <div className="bg-transparent dark:text-gray-100 text-xs text-gray-500 w-2/3">{
                                                        readyState == ReadyState.OPEN && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatReadWrite]) ?
                                                            t('application.transcript.chat.submit') :
                                                            t('application.transcript.wait')
                                                    }</div>
                                                    <NameEditable
                                                        className='bg-white dark:bg-gray-900 text-sm mb-2 mx-4 shadow-lg w-2/3 px-2 py-3 rounded rounded-lg border border-primary-700 max-h-80 min-h-20 overflow-y-scroll'
                                                        name={inputText}
                                                        editable={readyState == ReadyState.OPEN && UserHasRights(props.workspaceConfig?.role, [DbRights.SettingsChatReadWrite])}
                                                        needSubmit={(event) => { return (event.ctrlKey && event.key == 'Enter') || (event.metaKey && event.key == 'Enter') }}
                                                        save={(_: number, value: string) => { sendMessage(value); setInputText('') }}
                                                        icon={transcriptLoading || chatLoading ? <Spinner className="h-6 w-6"></Spinner> : <PaperAirplaneIcon className="h-6 w-6" />}
                                                    />
                                                </>

                                }

                            </div>

                        </div>
                    </>
                    : <></>
            }
        </>
    )
}