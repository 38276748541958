const encodeWAV = (samples: Float32Array, format: number, sampleRate: number, numChannels: number, bitDepth: number) => {
    const bytesPerSample = bitDepth / 8
    const blockAlign = numChannels * bytesPerSample

    const buffer = new ArrayBuffer(44 + samples.length * bytesPerSample)
    const view = new DataView(buffer)

    /* RIFF identifier */
    writeString(view, 0, 'RIFF')
    /* RIFF chunk length */
    view.setUint32(4, 36 + samples.length * bytesPerSample, true)
    /* RIFF type */
    writeString(view, 8, 'WAVE')
    /* format chunk identifier */
    writeString(view, 12, 'fmt ')
    /* format chunk length */
    view.setUint32(16, 16, true)
    /* sample format (raw) */
    view.setUint16(20, format, true)
    /* channel count */
    view.setUint16(22, numChannels, true)
    /* sample rate */
    view.setUint32(24, sampleRate, true)
    /* byte rate (sample rate * block align) */
    view.setUint32(28, sampleRate * blockAlign, true)
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, blockAlign, true)
    /* bits per sample */
    view.setUint16(34, bitDepth, true)
    /* data chunk identifier */
    writeString(view, 36, 'data')
    /* data chunk length */
    view.setUint32(40, samples.length * bytesPerSample, true)
    if (format === 1) { // Raw PCM
        floatTo16BitPCM(view, 44, samples)
    } else {
        writeFloat32(view, 44, samples)
    }

    return buffer
}

function interleave(inputL: Float32Array, inputR: Float32Array) {
    const length = inputL.length + inputR.length
    const result = new Float32Array(length)

    let index = 0
    let inputIndex = 0

    while (index < length) {
        result[index++] = inputL[inputIndex]
        result[index++] = inputR[inputIndex]
        inputIndex++
    }
    return result
}

function writeFloat32(output: DataView, offset: number, input: Float32Array) {
    for (let i = 0; i < input.length; i++, offset += 4) {
        output.setFloat32(offset, input[i], true)
    }
}

function floatTo16BitPCM(output: DataView, offset: number, input: Float32Array) {
    for (let i = 0; i < input.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, input[i]))
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true)
    }
}

function writeString(view: DataView, offset: number, s: string) {
    for (let i = 0; i < s.length; i++) {
        view.setUint8(offset + i, s.charCodeAt(i))
    }
}


export const audioBufferToWav = (audiobuffer: AudioBuffer, float32: boolean = false, mono: boolean = false): ArrayBuffer[] => {

    let result
    if (audiobuffer.numberOfChannels === 2 && mono == false) {
        result = interleave(audiobuffer.getChannelData(0), audiobuffer.getChannelData(1))
    } else {
        result = audiobuffer.getChannelData(0)
    }

    // Split into 5-minute chunks
    const samplesPerChunk = audiobuffer.sampleRate * 60 * 5; // 5 minutes of samples
    const chunks: ArrayBuffer[] = [];

    let i = 0
    while (i < result.length) {
        chunks.push(encodeWAV(
            result.slice(i, i + samplesPerChunk),
            float32 ? 3 : 1,
            audiobuffer.sampleRate,
            mono === false ? audiobuffer.numberOfChannels : 1,
            float32 ? 32 : 16
        ))
        i += samplesPerChunk
    }
    chunks.push(encodeWAV(
        result.slice(i - samplesPerChunk, result.length),
        float32 ? 3 : 1,
        audiobuffer.sampleRate,
        mono === false ? audiobuffer.numberOfChannels : 1,
        float32 ? 32 : 16
    ))

    return chunks
}
